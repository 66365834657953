<template>
    <span>
        {{ only_hour ? formatted_hour : formatted_value }}
    </span>
</template>

<script>
function td(v) {
    if (v.length < 2) v = "0" + v;
    return v;
}
export default {
    props: {
        modelValue: {},
        only_hour: { type: Boolean, default: false },
    },
    computed: {
        date_obj() {
            let date = this.modelValue;
            if (!(this.modelValue instanceof Date)) {
                let date_string = this.modelValue;
                if (date_string.indexOf("Z") == -1) date_string += "Z";
                date = new Date(date_string);
            }
            return isNaN(date) ? "" : date;
        },
        formatted_value() {
            try {
                let Y = String(this.date_obj.getFullYear());
                let M = String(this.date_obj.getMonth() + 1);
                let D = String(this.date_obj.getDate());
                let h = String(this.date_obj.getHours());
                let m = String(this.date_obj.getMinutes());
                return `${Y}-${td(M)}-${td(D)} ${td(h)}:${td(m)}`;
            } catch {
                if (["never", "now"].includes(this.modelValue)) return this.modelValue;
                return "---";
            }
        },
        formatted_hour() {
            try {
                let h = String(this.date_obj.getHours());
                let m = String(this.date_obj.getMinutes());
                return `${td(h)}:${td(m)}`;
            } catch {
                console.log("[!] Can't render time");
                if (["never", "now"].includes(this.modelValue)) return this.modelValue;
                return "---";
            }
        },
    },
};
</script>