import { useSelectedLogisticsStore } from "@/stores/selected_logistics";

// WATCHERS THAT LAUNCH ACTIONS FROM "SELECTED LOGISTICS" STATE CHANGES

let last_selected_actuals = []
let last_selected_branch = null
let last_selected_size = null

export const init_selected_logistics_watchers = function () {
    const selected_logistics_store = useSelectedLogisticsStore()


    function loadBranches(uuids) {
        selected_logistics_store.setSelectedBranch(null)
        selected_logistics_store.setSelectedSize(null)
        selected_logistics_store.setSelectedPartDesign(null)
        selected_logistics_store.setBranches([])
        selected_logistics_store.setSizes([])
        selected_logistics_store.setParts([])
        if (uuids.length != 0) {
            selected_logistics_store.loadBranchesByActualUuids(uuids)
        }
    }

    function loadSizesOrParts(payload) {
        selected_logistics_store.setSelectedSize(null)
        selected_logistics_store.setSelectedPartDesign(null)
        selected_logistics_store.setSizes([])
        selected_logistics_store.setParts([])
        if (payload.branch === null) {
            return
        } else if (!["timber", "plate"].includes(payload.branch)) {
            selected_logistics_store.loadParts(payload)
        } else if (payload.actuals.length != 0) {
            selected_logistics_store.loadSizesByActualUuidsAndBranch(payload)
        }
    }

    function loadParts(payload) {
        selected_logistics_store.setSelectedPartDesign(null)
        selected_logistics_store.setParts([])
        if (payload.actuals.length != 0 && payload.branch != null && payload.size != null) {
            selected_logistics_store.loadParts(payload)
        }
    }

    selected_logistics_store.$subscribe((mutation, state) => {

        const selected_actuals = state.selected_actuals
        const selected_branch = state.selected_branch
        const selected_size = state.selected_size

        if (selected_actuals != last_selected_actuals) {
            loadBranches(selected_actuals)
            loadSizesOrParts({ actuals: selected_actuals, branch: selected_branch })
            loadParts({ actuals: selected_actuals, branch: selected_branch, size: selected_size })

            last_selected_actuals = selected_actuals
        }

        if (selected_branch != last_selected_branch) {
            loadSizesOrParts({ actuals: selected_actuals, branch: selected_branch })
            loadParts({ actuals: selected_actuals, branch: selected_branch, size: selected_size })

            last_selected_branch = selected_branch
        }


        if (selected_size != last_selected_size) {
            loadParts({ actuals: selected_actuals, branch: selected_branch, size: selected_size })

            last_selected_size = selected_size
        }




    })

}