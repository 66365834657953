import { backend_api_v2 } from '/src/utils/apiv2'
import base from "./base"
import { defineStore } from 'pinia'

export const useRecordsStore = defineStore('records', {
    state: () => ({
        rcp: null,
        rcp_loading: false,
        issues: [],
    }),
    getters: {
        rcpIsRunning: state => state.rcp?.paused || !state.rcp?.start_date
    },
    actions: {
        setRCP(rcp) { this.rcp = rcp },
        loadingRCP(b) { this.rcp_loading = b },

        // Issues
        setIssues: base.actions.setter("issues"),
        replaceIssue: base.actions.updater("issues"),
        createIssue: base.actions.creator("issues"),
        updateIssue: base.actions.updater("issues"),
        removeIssue: base.actions.remover("issues"),

        loadRCP(payload) {
            this.loadingRCP(true)
            let url = `records/rcps/?${payload.level}=${payload.uuid}`
            return backend_api_v2.get(url)
                .then(({ data }) => {
                    this.loadingRCP(false)
                    // If no proof
                    if (data.results.length == 0) {
                        // If no results, set null cause we may be changing actual.
                        this.setRCP(null)
                    } else {
                        // If results, set first.
                        this.setRCP(data.results[0])
                    }
                })
                .catch(e => {
                    console.log(`Cant load ready constructible proof: ${e}`)
                })
        },
        createRCP(payload) {
            let epk = payload.level == "assembly" ? "assemblies" : "modules"
            let url = `constructibles/${epk}/${payload.uuid}/create_proof/`
            return backend_api_v2.get(url)
                .then((response) => {
                    // And finally if really created, reload recursivelly.
                    if ([200, 201].includes(response.status)) {
                        return this.loadRCP(payload)
                    }
                })
                .catch(e => {
                    console.log(`Cant create proof: ${e}`)
                    this.setRCP(null)
                })
        },
        putRCP(proof) {
            return backend_api_v2.put(`records/rcps/${proof.uuid}/`, proof)
                .then(({ data }) => {
                    this.setRCP(data)
                })
        },
        deleteRCP(proof_uuid) {
            return backend_api_v2.delete(`records/rcps/${proof_uuid}/`)
                .then(() => {
                    this.setRCP(null)
                })
        },
        multiRCPFlushByActualUuids(actuals_uuids) {
            return backend_api_v2.post('constructibles/actuals/bulk_proof_flush/', actuals_uuids)
                .catch(e => { console.log(`Cant batch flush proofs actuals: ${e}`) })
        },

        // Issues
        loadIssues: base.actions.loader_pr("records/issues/", "Issues"),
        postIssue: base.actions.poster("records/issues/", "Issue"),
        putIssue: base.actions.putter("records/issues/", "Issue"),
        deleteIssue: base.actions.deleter("records/issues/", "Issue"),
        refreshIssue: base.actions.refresher("records/issues/", "Issue"),
    },
})
