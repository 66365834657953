import { useCloudstorageStore } from "@/stores/cloudstorage";
import bus from "/src/utils/event_bus";
import { useViewerStore } from "@/stores/viewer";

// WATCHERS THAT LAUNCH ACTIONS FROM CLOUDSTORAGE STATE CHANGES

let last_modd_heavy_meta_pck = null
let last_assd_heavy_meta_pck = null
let last_passd_heavy_meta_pck = null
let last_modd_res_pck = null
let last_assd_res_pck = null


function get_lowest_dwg_number(cfs) {
    // Extract dwg_numbers from the objects and cast to numbers
    const dwg_numbers = cfs.map(cf => cf.meta && cf.meta?.dwg_number || 100)

    // Filter out undefined, null, and non-numeric values
    const valid_dwg_numbers = dwg_numbers
        .filter(num => num !== undefined && num !== null && !isNaN(parseFloat(num)))
        .map(Number) // Cast strings to numbers

    // Find the minimum dwg_number
    return Math.min(...valid_dwg_numbers)
}


export const init_cloudstorage_watchers = function () {
    const cloudstorage_store = useCloudstorageStore()
    const viewer_store = useViewerStore()

    cloudstorage_store.$subscribe((mutation, state) => {


        const modd_heavy_meta_pck = state.module_design_packages.find(pck => pck.name == "heavy_meta")
        const assd_heavy_meta_pck = state.assembly_design_packages.find(pck => pck.name == "heavy_meta")
        const passd_heavy_meta_pck = state.parent_assembly_design_packages.find(pck => pck.name == "heavy_meta")
        const modd_res_pck = state.module_design_packages.find(pck => pck.name == "resources")
        const assd_res_pck = state.assembly_design_packages.find(pck => pck.name == "resources")

        if (modd_heavy_meta_pck != last_modd_heavy_meta_pck) {
            viewer_store.loadBom()

            last_modd_heavy_meta_pck = modd_heavy_meta_pck
        }


        if (assd_heavy_meta_pck != last_assd_heavy_meta_pck) {
            viewer_store.loadBom()

            last_assd_heavy_meta_pck = assd_heavy_meta_pck
        }


        if (passd_heavy_meta_pck != last_passd_heavy_meta_pck) {
            viewer_store.loadBom()

            last_passd_heavy_meta_pck = passd_heavy_meta_pck
        }


        if (modd_res_pck != last_modd_res_pck) {

            console.log("🍍👁️ Setting up viewer store with new module")

            bus.emit('events/viewer/default_zone_selection')
            // Set correct left buttons index
            const dwgs = modd_res_pck?.files.filter(cf => cf.meta.section == "drawing")
            if (dwgs && dwgs.length <= viewer_store.left_panel_dwg_number) {
                viewer_store.setLeftPanelDWGNumber(get_lowest_dwg_number(modd_res_pck?.files || []))
            }
            bus.emit("events/viewer/redraw_left_pdf");

            last_modd_res_pck = modd_res_pck
        }


        if (assd_res_pck != last_assd_res_pck) {
            console.log("[i] Setting up viewer store with new assembly")

            bus.emit('events/viewer/default_zone_selection')
            // Set correct left buttons index
            const dwgs = assd_res_pck?.files.filter(cf => cf.meta.section == "drawing")
            if (dwgs && dwgs.length <= viewer_store.left_panel_dwg_number) {
                viewer_store.setLeftPanelDWGNumber(get_lowest_dwg_number(assd_res_pck?.files || []))
            }
            bus.emit("events/viewer/redraw_left_pdf");

            last_assd_res_pck = assd_res_pck
        }



    })

}