import { useCloudstorageStore } from "@/stores/cloudstorage";
import { useCompanyStore } from "@/stores/company";
import bus from "/src/utils/event_bus";
import { useSelectedStore } from "@/stores/selected";
import { allCountersFromSaveOrClean } from "@/stores/quantities";
import { useReportsStore } from "@/stores/reports";
import { useAppStore } from "@/stores/app";
import { useConstructiblesStore } from "@/stores/constructibles";
import { useRecordsStore } from "@/stores/records";

// WATCHERS THAT LAUNCH ACTIONS FROM "SELECTED" STATE CHANGES

let last_selected_project = null
let last_selected_mod = null
let last_selected_ass = null
let last_selected_modd = null
let last_selected_assd = null
let last_pds = []

let last_date = null


export const init_selected_watchers = function () {
    const company_store = useCompanyStore()
    const cloudstorage_store = useCloudstorageStore()
    const selected_store = useSelectedStore()
    const reports_store = useReportsStore()
    const records_store = useRecordsStore()
    const app_store = useAppStore()
    const constructibles_store = useConstructiblesStore()


    selected_store.$subscribe((mutation, state) => {
        // -----------
        // PACKAGES
        // -----------


        console.log("🍍👁️ -> triggered selected watchers")

        let uuid = state.selected_project?.uuid
        if (uuid != last_selected_project) {

            // PACKAGES
            if (uuid != "all" && uuid != undefined)
                cloudstorage_store.loadProjectPackages(uuid)
            cloudstorage_store.set_project_packages([])  // always clean even if company wide

            // PROJECT DB ASSETS
            // if (uuid == "all" || !uuid) {
            //     // Clean
            //     constructibles_store.setModuleDesigns([])
            //     constructibles_store.setAssemblyDesigns([])
            //     constructibles_store.setBatches([])
            //     constructibles_store.setModules([])
            //     constructibles_store.setAssemblies([])
            // }
            // else {
            //     app_store.pushLoading("project_resources")
            //     Promise.all([
            //         constructibles_store.loadModuleDesigns(uuid),
            //         constructibles_store.loadAssemblyDesigns(uuid),
            //         constructibles_store.loadBatches(uuid),
            //         constructibles_store.loadModules(uuid),
            //         constructibles_store.loadAssemblies(uuid),
            //     ]).finally(() => app_store.popLoading("project_resources"))
            // }


            // Clean (there was a change, it needs to be cleaned)
            constructibles_store.setModuleDesigns([])
            constructibles_store.setAssemblyDesigns([])
            constructibles_store.setBatches([])
            constructibles_store.setModules([])
            constructibles_store.setAssemblies([])

            // Actually load resources
            if (uuid != "all" && uuid) {
                app_store.pushLoading("project_resources")
                Promise.all([
                    constructibles_store.loadModuleDesigns(uuid),
                    constructibles_store.loadAssemblyDesigns(uuid),
                    constructibles_store.loadBatches(uuid),
                    constructibles_store.loadModules(uuid),
                    constructibles_store.loadAssemblies(uuid),
                ]).finally(() => app_store.popLoading("project_resources"))
            }


            // QUANTITIES COUNTERS
            allCountersFromSaveOrClean()  // this is debounced store action

            // REPORTS
            reports_store.loadReports(uuid)


            // TOUCH
            last_selected_project = uuid
        }

        uuid = state.selected_mod?.uuid
        if (uuid != last_selected_mod) {
            last_selected_mod = uuid
            // Packages
            if (uuid != undefined)
                cloudstorage_store.loadModulePackages(uuid)
            cloudstorage_store.set_module_packages([])

            // RCP
            records_store.setRCP(null)
            if (uuid) records_store.loadRCP({ level: "module", uuid })

            // HOT ISSUES
            selected_store.setHotIssues([])
            if (!uuid) return
            selected_store.loadHotIssues({ module: uuid })

        }

        uuid = state.selected_ass?.uuid
        if (uuid && uuid != last_selected_ass) {
            last_selected_ass = uuid
            // Packages
            if (uuid != undefined)
                cloudstorage_store.loadAssemblyPackages(uuid)
            cloudstorage_store.set_assembly_packages([])

            // RCP
            records_store.setRCP(null)
            if (uuid) records_store.loadRCP({ level: "assembly", uuid })

            // HOT ISSUES
            selected_store.setHotIssues([])
            if (!uuid) return
            selected_store.loadHotIssues({ assembly: uuid })
        }

        uuid = state.selected_modd?.uuid
        if (uuid != last_selected_modd) {
            last_selected_modd = uuid
            if (uuid != undefined)
                cloudstorage_store.loadModuleDesignPackages(uuid)
            cloudstorage_store.set_module_design_packages([])
        }

        uuid = state.selected_assd?.uuid
        if (uuid != last_selected_assd) {
            last_selected_assd = uuid
            if (uuid != undefined)
                cloudstorage_store.loadAssemblyDesignPackages(uuid)
            cloudstorage_store.set_assembly_design_packages([])
        }

        // store.watch(
        //     (state) => state.v2.selected.selected_ass?.parent_assembly,
        //     (pass) => console.log("PARENT ASS!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", pass)
        // )


        let pds = state.selected_assd?.parent_designs || []
        if (last_pds != pds) {
            if (pds.length > 0) {
                const uuid = pds[0]  // We simply pick first parent. Otherwise should be determined by assembly in prev watcher.
                cloudstorage_store.loadParentAssemblyDesignPackages(uuid)
            }
            cloudstorage_store.set_parent_assembly_design_packages([])
            last_pds = [...pds]
        }


        // BY DATE PROJECT SELECTION
        const date = state.selected_date
        uuid = state.selected_project?.uuid
        // Either change of date, or change from pr to company_wide
        if (last_date != date || (uuid == "all" && uuid != last_selected_project)) {
            app_store.pushLoading("project_resources")
            Promise.all([
                constructibles_store.loadModuleDesignsByDate(date),
                constructibles_store.loadAssemblyDesignsByDate(date),
                constructibles_store.loadBatchesByDate(date),
                constructibles_store.loadModulesByDate(date),
                constructibles_store.loadAssembliesByDate(date),
            ]).finally(() => app_store.popLoading("project_resources"))
            last_date = date
            last_selected_project = uuid
        }


    })


    // PACKAGES EVENTS
    bus.on(
        "events/cloudstorage/reloadCompanyPackages",
        () => cloudstorage_store.loadCompanyPackages(company_store.company?.uuid)
    )
    bus.on(
        "events/cloudstorage/reloadProjectPackages",
        () => cloudstorage_store.loadProjectPackages(selected_store.selected_project?.uuid)
    )
    bus.on(
        "events/cloudstorage/reloadDesignPackages",
        () => {
            cloudstorage_store.set_assembly_design_packages([])
            cloudstorage_store.set_module_design_packages([])
            let assd_uuid = selected_store.selected_assd?.uuid
            let modd_uuid = selected_store.selected_modd?.uuid
            if (assd_uuid) cloudstorage_store.loadAssemblyDesignPackages(assd_uuid)
            if (modd_uuid) cloudstorage_store.loadModuleDesignPackages(modd_uuid)
        })
}
