import bus from "/src/utils/event_bus";
import { useSelectedStore } from "@/stores/selected";
import { useConstructiblesStore } from "@/stores/constructibles";
import {
    useQuantitiesStore,
    assemblyCountersFromSaveOrClean,
    moduleCountersFromSaveOrClean,
    allCountersFromSaveOrClean,
} from "@/stores/quantities"

// WATCHERS THAT LAUNCH ACTIONS FROM CONSTRUCTIBLES STATE CHANGES

let MODDS_TO_FETCH = []
let ASSDS_TO_FETCH = []

let last_annotatedDesignsFromLevelOnlyLastRevisions_all = []

let BATCH_FIRST_RUN = true
let MODD_FR = true
let ASSD_FR = true

let old_batches = []
let old_modds = []
let old_assds = []
let old_mods = []
let old_asss = []


export const init_constructibles_watchers = function () {
    const constructibles_store = useConstructiblesStore()
    const selected_store = useSelectedStore()

    constructibles_store.$subscribe((mutation, state) => {


        // THIS IS TO REFRESH LIVE CONSTRUCTIBLES
        // TODO THIS SHOULD BE IMMEDIATE=TRUE
        console.log("🍍👁️ -> triggered constructible watchers")
        const designs = constructibles_store.annotatedDesignsFromLevelOnlyLastRevisions("all")
        if (designs != last_annotatedDesignsFromLevelOnlyLastRevisions_all) {
            let ds = designs.filter(d => ["working", "waiting"].includes(d?.meta?.analysis))

            // Fill buffers
            let assds = ds.filter(d => d.level == "assembly")
            let modds = ds.filter(d => d.level == "module")
            ASSDS_TO_FETCH = assds.map(assd => assd.uuid).filter(uuid => !!uuid)
            MODDS_TO_FETCH = modds.map(modd => modd.uuid).filter(uuid => !!uuid)

            // Launch timeouts
            if (ASSDS_TO_FETCH.length != 0)
                setTimeout(() => {
                    // Call it
                    constructibles_store.refreshAssemblyDesigns([...ASSDS_TO_FETCH])
                    // On execution of timeout, clean to avoid repetition
                    ASSDS_TO_FETCH = []
                }, 25000)
            if (MODDS_TO_FETCH.length != 0)
                setTimeout(() => {
                    // Call it
                    constructibles_store.refreshModuleDesigns([...MODDS_TO_FETCH])
                    // On execution of timeout, clean to avoid repetition
                    MODDS_TO_FETCH = []
                }, 25000)
            last_annotatedDesignsFromLevelOnlyLastRevisions_all = [...designs]
        }


        const batches = state.batches
        if (batches != old_batches) {
            // THIS IS FOR QUANTITIES COUNTERS
            const nuuids = batches.map(b => b.uuid)
            const ouuids = old_batches.map(b => b.uuid)
            const removedBatches = ouuids.filter(ouuid => !nuuids.includes(ouuid));
            if (removedBatches.length > 0 || BATCH_FIRST_RUN) {
                allCountersFromSaveOrClean()
                BATCH_FIRST_RUN = false
            }
            old_batches = [...batches] //set for next run
        }

        const new_modds = state.module_designs
        if (new_modds != old_modds) {
            // THIS IS FOR QUANTITIES COUNTERS
            // Only refresh if they removed designs.
            const nuuids = new_modds.map(mod => mod.uuid)
            const ouuids = old_modds.map(mod => mod.uuid)
            const removed_modds = ouuids.filter(ouuid => !nuuids.includes(ouuid))
            if (removed_modds.length > 0 || MODD_FR) {
                moduleCountersFromSaveOrClean()
                MODD_FR = false
            }
            old_modds = [...new_modds] //set for next run
        }

        const new_assds = state.assembly_designs
        if (new_assds != old_assds) {
            // THIS IS FOR QUANTITIES COUNTERS
            // Only refresh if they removed designs.
            const nuuids = new_assds.map(ass => ass.uuid)
            const ouuids = old_assds.map(ass => ass.uuid)
            const removed_assds = ouuids.filter(ouuid => !nuuids.includes(ouuid))
            if (removed_assds.length > 0 || ASSD_FR) {
                assemblyCountersFromSaveOrClean()
                ASSD_FR = false
            }
            old_assds = [...new_assds] //set for next run
        }

        let modules = state.modules
        if (modules != old_mods) {
            moduleCountersFromSaveOrClean()
            modules = [...old_mods]
        }

        let assemblies = state.assemblies
        if (assemblies != old_asss) {
            assemblyCountersFromSaveOrClean()
            assemblies = [...old_asss]
        }

    })

    bus.on("events/constructibles/reloadConstructibles", () => {
        let pr_uuid = selected_store.selected_project?.uuid
        constructibles_store.loadAssemblies(pr_uuid)
        constructibles_store.loadModules(pr_uuid)
    })

    bus.on("events/constructible/routeToMyNextActual", () => {
        let first = constructibles_store.myFirst
        console.log("[!] TODO!!!!: push here route to:", first.uuid)
    })

}