<template>
    <v-list-item link :to="route" active-class="accent--text" :prepend-icon="prepend_icon">
        <v-list-item-title class="text-subtitle-2">
            {{ dname }}
            <IconRevision :rev="rev" :old="oldrev" />
            <span class="mx-1"><strong>{{ constructible.priority }}</strong>/{{ max_batch_priority }}</span>
            <span class="text-error" v-if="delayed">
                <v-icon color="error" size="x-small"> mdi-clock-outline </v-icon>
                {{ constructible.scheduled_prod_date }}
            </span>
            <span class="mx-1 font-weight-light text-grey"> {{ constructible.uuid.slice(0, 8) }} </span>
        </v-list-item-title>
        <v-list-item-subtitle class="text-no-wrap">
            <v-chip size="x-small" variant="outlined" color="info"> {{ batch_chip }} </v-chip>
            <br />
            <v-chip v-if="status == 'ready'" size="x-small" color="success" class="mt-1">
                {{ t("ready") }}
            </v-chip>
            <div v-else>
                <v-chip :class="i == 0 ? 'mt-1' : 'ml-1 mt-1'" v-for="(sc, i) in station_chips" :key="i" size="x-small"
                    :variant="sc.outlined ? 'outlined' : undefined" :color="sc.color">{{ sc.number }}</v-chip>
            </div>
        </v-list-item-subtitle>
    </v-list-item>
</template>

<script>
import IconRevision from '../../renderers/IconRevision.vue';
import { today } from "/src/utils/time.js"
import { useFullPitStore } from "@/stores/app_common";
export default {
    setup() {
        const pit_store = useFullPitStore()
        return { ...pit_store, }
    },
    components: {
        IconRevision,
    },
    props: ["constructible", "level"],
    computed: {
        prepend_icon() {
            if (this.loading_this) return "mdi-loading mdi-spin"
            else if (this.status == "ready") return "mdi-check"
            else if (this.status == "working") return "mdi-hammer-screwdriver"
            else return "mdi-calendar-clock"
        },
        loading_this() {
            return this.app_store.active_loaders.includes("constructible_" + this.constructible.uuid)
        },
        design() {
            return this.constructibles_store[`${this.level}_designs`].find(
                (d) => d.uuid == this.constructible?.design
            );
        },
        design_revisions() {
            return this.constructibles_store[`${this.level}_designs`].filter(
                (d) =>
                    d.name == this.design?.name
            );
        },
        batch() {
            return this.constructibles_store.batches.find(
                (b) => b.uuid == this.constructible?.batch
            );
        },
        max_batch_priority() {
            let actuals = this.constructibles_store.annotatedActualsFromLevel(this.level)
            let max_priority = 0;
            let actuals_to_look
            if (this.session_store.work_by_date) {
                actuals_to_look = actuals
            } else {
                actuals_to_look = actuals.filter(
                    (ass_) => ass_.batch == this.constructible?.batch
                );
            }
            actuals_to_look.forEach((ass) => {
                if (ass.priority > max_priority) {
                    max_priority = ass.priority;
                }
            });
            return max_priority;
        },
        dname() {
            return this.design?.name || "---";
        },
        rev() {
            return this.design?.revision || 0;
        },
        batch_chip() {
            let batch_name = this.batch ? this.batch.name : "";
            let phase = this.batch ? this.batch.phase : "";
            return `${phase} | ${batch_name}`;
        },
        oldrev() {
            let max_rev = Math.max(
                ...this.design_revisions.map((d) => d.revision)
            );
            let this_rev = this.design?.revision || 0;
            return this_rev != max_rev
        },
        status() {
            return this.constructible?.status || ""
        },
        delayed() {
            if (!this.constructible.scheduled_prod_date) return false
            else if (this.constructible.scheduled_prod_date < today()) return true
            else return false
        },
        station_chips() {
            let stations =
                this.session_store.my_selected_line_object?.stations || [];
            let station_chips = stations.map((s) => {
                let sta_name = s.name ? s.name : "unknown";
                let status = this.constructible?.stations_status[sta_name];
                let color = "info";
                let outlined = true;
                if (status == "working") {
                    outlined = false;
                    color = "warning";
                } else if (status == "disabled") {
                    outlined = true;
                    color = "grey";
                } else if (status == "ready") {
                    outlined = false;
                    color = "success";
                }
                return { number: s.order, color, outlined };
            });
            station_chips.sort((a, b) => a.number - b.number);
            return station_chips
            // return [
            //     { outlined: false, color: "success", number: 1 },
            //     { outlined: false, color: "success", number: 2 },
            //     { outlined: false, color: "warning", number: 3 },
            //     { outlined: true, color: "info", number: 4 }
            // ];
        },
        route() {
            return `/viewer/${this.level}/${this.constructible?.uuid}`
        }
    },
    // mounted() {
    //     console.log("mounted", this.constructible?.uuid)
    // },
    // unmounted() {
    //     console.log("unmounted", this.constructible?.uuid)
    // },
}

</script>